import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapState } from 'vuex'
import filters from '@/libs/filters'

export default {
  components: {
    vSelect,
  },
  filters: { rupiah: filters.rupiah },

  data() {
    return {
      expiredDate: 0,
      notification: {},
      pickupNotification: false,
      codNotification: false,
      codTooltip: 'Aktifkan Notifikasi',
      notificationTooltip: 'Aktifkan Notifikasi',
      messageTemplateCod: '',
      messageTemplatePickup: '',
      listFitur: [{ value: 1, name: 'Notifikasi WhatsApp Premium' }],
      listSubscription: [],
      subscription: {},
      fitur: { value: 1, name: 'Notifikasi WhatsApp Premium' },
      saldo_kompay: 0,
      disabledSubcription: true,
      loadingSubcription: false,
      isTemplate: true,
      lifetime: false,

      defaultWa: null,
      numbLogout: null,
    }
  },

  computed: {
    ...mapState('dashboard', ['profile']),
    ...mapState('whatsapp', ['phone_number', 'check_wa', 'connected_wa', 'list_message', 'dashboard_wa']),
  },

  async mounted() {
    await this.$store.dispatch('whatsapp/getUpdateDashboardWa')
    await this.$store.dispatch('whatsapp/getUpdatePhoneNumber')
    await this.$store.dispatch('whatsapp/getUpdateConnectedWa')
    if (this.$route.params.modal === 'renew') {
      this.modalRenew()
    }
    this.checkWA()
  },

  methods: {
    async checkWA() {
      this.defaultWa = this.check_wa.is_notification_whatsapp
      if (this.defaultWa !== 2) {
        this.$router.push({ name: 'opsional-feature' })
      } else {
        this.fetchMessages()
      }
    },
    async fetchMessages() {
      await this.$store.dispatch('whatsapp/getUpdateListMessage')
      this.codNotification = this.list_message.list_messages[1].status_message === 'active'
      this.pickupNotification = this.list_message.list_messages[0].status_message === 'active'
      if (this.list_message.list_messages[1].is_custom === 0) {
        this.messageTemplateCod = this.list_message.default_message.message_arrived_at
      } else {
        this.messageTemplateCod = this.list_message.list_messages[1].message
      }
      if (this.list_message.list_messages[0].is_custom === 0) {
        this.messageTemplatePickup = this.list_message.default_message.message_pickup
      } else {
        this.messageTemplatePickup = this.list_message.list_messages[0].message
      }

      if (this.codNotification === true) {
        this.codTooltip = 'Matikan Notifikasi'
      } else {
        this.codTooltip = 'Aktifkan Notifikasi'
      }

      if (this.pickupNotification === true) {
        this.notificationTooltip = 'Matikan Notifikasi'
      } else {
        this.notificationTooltip = 'Aktifkan Notifikasi'
      }
    },
    logout() {
      this.$http_komship
        .delete(`/v2/wap/logout/${this.numbLogout.id}`)
        .then(async response => {
          if (response.data.code === 200) {
            await this.$store.dispatch('whatsapp/getUpdateConnectedWa')
            await this.$store.dispatch('whatsapp/getUpdatePhoneNumber')
            this.$bvModal.hide('modal-logout')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'AlertCircleIcon',
                text: 'Berhasil menghapus nomor whatsapp',
                variant: 'success',
              },
            })
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          })
        })
    },
    async modalRenew() {
      await this.$http_komship
        .get('/v1/setting/notification-whatsapp-premium-fee')
        .then(response => {
          const { data } = response.data
          this.saldo_kompay = data.kompay_balance
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          })
        })
      await this.$http_komship
        .get('/v1/setting/renew-whatsapp-premium-fee')
        .then(response => {
          const { data } = response.data
          this.listSubscription = data
          this.subscription = { ...this.listSubscription[0] }
          this.subscriptionChange()
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          })
        })
      this.$bvModal.show('modal-renew-subcription')
    },
    subscriptionChange() {
      if (this.saldo_kompay < this.subscription.nominal) {
        this.disabledSubcription = true
      } else {
        this.disabledSubcription = false
      }
    },
    subscribe() {
      this.disabledSubcription = true
      this.loadingSubcription = true
      const formData = new FormData()
      formData.append('is_renew', '1')
      formData.append('id_whatsapp_fee', this.subscription.id)
      this.$http_komship
        .post('/v1/setting/renew-whatsapp-subscription', formData)
        .then(async () => {
          await this.$store.dispatch('whatsapp/getUpdateDashboardWa')
          this.$bvModal.hide('modal-renew-subcription')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'AlertCircleIcon',
              text: 'Berhasil melakukan pembayaran dan perpanjangan',
              variant: 'success',
            },
          })
          this.loadingSubcription = false
        })
        .catch(() => {
          this.disabledSubcription = false
          this.loadingSubcription = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal melakukan pembayaran dan perpanjangan',
              variant: 'danger',
            },
          })
        })
    },
    setTemplate(value) {
      let messageId = 0
      let toastSukses = ''
      let toastFailed = ''
      let statusMessagePickup = ''
      let statusMessageCod = ''
      const body = {}

      if (this.codNotification === true) {
        this.codTooltip = 'Matikan Notifikasi'
        statusMessageCod = 'active'
        this.isTemplate = true
      } else {
        this.codTooltip = 'Aktifkan Notifikasi'
        statusMessageCod = 'nonactive'
      }

      if (this.pickupNotification === true) {
        this.notificationTooltip = 'Matikan Notifikasi'
        statusMessagePickup = 'active'
        this.isTemplate = false
      } else {
        statusMessagePickup = 'nonactive'
        this.notificationTooltip = 'Aktifkan Notifikasi'
      }

      if (value === 'pickup') {
        messageId = this.list_message.list_messages[0].id
        body.status_message = statusMessagePickup
        toastSukses = 'Sukses mengaktifkan notifikasi Whatsapp Pickup'
        toastFailed = 'Gagal mengaktifkan notifikasi Whatsapp Pickup'
      } else {
        messageId = this.list_message.list_messages[1].id
        body.status_message = statusMessageCod
        toastSukses = 'Sukses mengaktifkan notifikasi Whatsapp Sampai di Kota tujuan'
        toastFailed = 'Gagal mengaktifkan notifikasi Whatsapp Sampai di Kota tujuan'
      }

      this.$http_komship
        .put(`/v2/wap/update-status-message/${messageId}`, body)
        .then(async res => {
          await this.$store.dispatch('whatsapp/getUpdateListMessage')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'AlertCircleIcon',
              text: toastSukses,
              variant: 'success',
            },
          }, 500)
        })
        .catch(() => {
          if (value === 'pickup') {
            this.pickupNotification = false
          } else {
            this.codNotification = false
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: toastFailed,
              variant: 'danger',
            },
          }, 500)
        })
    },
    hoverTemplate(template) {
      if (template === 'cod') {
        this.isTemplate = true
      } else {
        this.isTemplate = false
      }
    },
    setNumb(data, value) {
      if (value === 'delete') {
        this.$bvModal.show('modal-logout')
        this.numbLogout = data
      } else {
        this.$router.push({ name: 'koneksi-wa', params: { number: data } })
      }
    },
    redirectLog(value) {
      if (this.connected_wa) {
        this.$router.push({ name: 'riwayat-notifikasi', params: { card: value } })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: 'Tambahkan nomor whatsapp terlebih dahulu',
            variant: 'danger',
          },
        })
      }
    },
  },
}
